<template>
  <slick ref="slick" :options="slickOptions" class="slider-news-states">
    <service-tile
      v-for="service in services"
      v-bind:key="service.id"
      :data="service"
      block-class="news-service-items-slider"
    />
  </slick>
</template>

<script>
import Slick from "vue-slick";
import ServiceTile from "@/components/page/services/tiles/ServiceTile";
export default {
  name: "Services",
  components: { ServiceTile, Slick },
  props: {
    services: Array
  },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-states'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-states'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style scoped></style>
