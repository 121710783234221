<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="services-wrapper-detail">
        <router-link to="/services" class="services-other">
          <i class="fas fa-arrow-left"></i>
          <span>Сервисы</span>
        </router-link>
        <h2>{{ service.name }}</h2>
        <div class="services-detail-content">
          <span v-html="service.description"></span>
          <a
            class="services-detail-link-more"
            target="_blank"
            :href="service.link"
          >
            Перейти
          </a>
          <p class="services-detail-price">{{ service.price }}</p>
        </div>
      </div>
      <img
        :src="require('@/assets/img/' + service.cover)"
        class="absolute-services-inner"
        alt=""
      />
    </div>
    <service-slider-block />
  </div>
</template>

<script>
import ServiceSliderBlock from "@/components/page/services/ServiceSliderBlock";
export default {
  name: "Service",
  components: { ServiceSliderBlock },
  data() {
    return {
      service: {
        cover: "modul.png",
        name: "Интернет-банк для предпринимателей",
        link: "#",
        price: "Стоимость: бесплатно",
        description:
          "<p>СберСоветник – это персональная аналитика для бизнеса.</p>" +
          "<p>Сервис знакомит ближе с вашими клиентами. Показывает предпочтения клиентов. Социально-демографический профиль. Отслеживает отклонения среднего чека, посещаемость торговой точки. Сравнивает ваш бизнес с отраслью и рынком. Следит за налогами через налоговый календарь, чтобы вы не просрочили очередной платеж и не получили штраф. Для новичков в бизнесе есть пошаговая инструкция по открытию бизнеса. Вся аналитика представлена в виде графиков.</p>"
      }
    };
  }
};
</script>

<style scoped></style>
