<template>
  <site-section section-class="services-slider">
    <template v-slot:header>
      <h2>Вам также подойдут</h2>
    </template>
    <template v-slot:content>
      <services :services="services" />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Services from "@/components/page/slick/Services";
export default {
  name: "ServiceSliderBlock",
  components: { SiteSection, Services },
  data() {
    return {
      services: [
        {
          id: 0,
          cover: "modul.png",
          name: "Интернет-банк для предпренимателей",
          price: "Бесплатно"
        },
        {
          id: 1,
          cover: "pw-1.png",
          name: "Сервис электронного документооборота",
          price: "От 990 руб/мес"
        },
        {
          id: 2,
          cover: "modul.png",
          name: "Интернет-банк для предпренимателей",
          price: "Бесплатно"
        },
        {
          id: 3,
          cover: "modul.png",
          name: "Интернет-банк для предпренимателей",
          price: "Бесплатно"
        },
        {
          id: 4,
          cover: "modul.png",
          name: "Интернет-банк для предпренимателей",
          price: "Бесплатно"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
